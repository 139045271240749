const Content = {
  left: {
    imageSrc: 'shirt_white.png',
    linkTo: null,
    headLine: null,
    text: null,
    order: true
  },
  right: {
    imageSrc: null,
    linkTo: null,
    headLine: null,
    text: `Загрузите изображение и разместите его на `,
    order: true,
    price: 1200
  },
  credits: {
    phone: '+7 926 834 59 22',
    email: 'test@test.test',
    vk: 'mashamedvedik',
    inst: 'mashamedvedik'
  }
};

export default Content;
